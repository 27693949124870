<app-modal [size]="'sm'" *ngIf="modalName === modals.GENERIC && showModal">
    <section class="error-modal">
    <div class="d-flex flex-column">
        <img width="147" class="mx-auto" src="/assets/images/group-21.svg" alt="pig" />
        <h3 class="mt-4 flexo-bold font-size-20 line-height-28 text-center color-primary_700">El destinatario no está disponible</h3>
        <p class="mt-3 flexo-regular font-size-16 line-height-20 text-center">Puedes cambiar el método de envío o<br> probar con otro destinatario</p>

        <ng-container>
            <div class="extra-button-size mx-auto mt-3">
                <bcp-button shape="rectangle" tier="primary" size="lg" full-width="true" (click)="goToReceptorYape(true)">Enviar a otro destinatario</bcp-button>
            </div>
        </ng-container>
        <div class="extra-button-size mx-auto mt-3">
            <bcp-button shape="rectangle" tier="secondary" size="lg" full-width="true" (click)="goToHome()">Volver al home</bcp-button>
        </div>
    </div>
    </section>
</app-modal>

<app-modal [size]="'sm'" *ngIf="modalName === modals.RECOVERY && showModal">
    <section class="error-modal">
    <div class="d-flex flex-column">
        <img width="147" class="mx-auto" src="/assets/images/group-21.svg" alt="pig" />
        <h3 class="mt-4 flexo-bold font-size-20 line-height-28 text-center color-primary_700">Los datos del destinario no coinciden</h3>
        <p class="mt-3 flexo-regular font-size-16 line-height-20 text-center">Para continuar revisa la información ingresada</p>

        <ng-container>
            <div class="extra-button-size mx-auto mt-3">
                <bcp-button shape="rectangle" tier="primary" size="lg" full-width="true" (click)="goToReceptorYape(false)">Ir a revisar datos</bcp-button>
            </div>
        </ng-container>
    </div>
    </section>
</app-modal>

<app-modal [size]="'sm'" *ngIf="modalName === modals.NO_RESPONSE && showModal">
    <section class="error-modal">
    <div class="d-flex flex-column">
        <img width="147" class="mx-auto" src="/assets/images/group-21.svg" alt="pig" />
        <h3 class="mt-4 flexo-bold font-size-20 line-height-28 text-center color-primary_700">Los envíos a Yape no están disponibles por el momento</h3>
        <p class="mt-3 flexo-regular font-size-16 line-height-20 text-center">Intenta con otro método de envío</p>

        <ng-container>
            <div class="extra-button-size mx-auto mt-3">
                <bcp-button shape="rectangle" tier="primary" size="lg" full-width="true" (click)="goToShippingBCP()">Enviar a cuenta BCP</bcp-button>
            </div>
        </ng-container>
        <div class="extra-button-size mx-auto mt-3">
            <bcp-button shape="rectangle" tier="secondary" size="lg" full-width="true" (click)="goToHome()">Volver al home</bcp-button>
        </div>
    </div>
    </section>
</app-modal>

