import { Component, OnInit, Input, Output, EventEmitter,inject } from '@angular/core';
import { ASSETS, AlertTypes } from 'src/app/core/constants';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { SafePipe } from 'src/app/core/pipes/safe.pipe';
@Component({
  selector: 'app-alert-message',
  templateUrl: './alert-message.component.html',
  styleUrls: ['./alert-message.component.scss']
})
export class AlertMessageComponent implements OnInit {

  @Input() type: string = AlertTypes.SUCCESS;
  @Input() aditionalClass : string 
  @Input() msg: string = '';aditionalclass
  @Input() hasCloseAlert: boolean = false;
  @Output() emitter = new EventEmitter<boolean>();

  alertImg: string = ASSETS.IMG_CHECK_VALID;
  alertCloseImg: string = ASSETS.IMG_ICON_CLOSE_GREEN;
  private safePipe = inject(SafePipe);
  safeMessage!: SafeHtml;

  constructor(protected sanitizer: DomSanitizer) {}

  ngOnInit(): void {
    this.setStyleByType();
    this.safeMessage = this.safePipe.transform(this.msg, 'html') as SafeHtml;
  }

  setStyleByType() {
    if (this.type === AlertTypes.ERROR) {
      this.alertImg = ASSETS.IMG_CHECK_ERROR;
    }
    else{
      this.alertImg = ASSETS.IMG_CHECK_SUCCESS;
    }
  }

  triggerEvent(): void {
    this.emitter.emit(false);
  }
}
