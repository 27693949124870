import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, inject } from '@angular/core';
import { Router } from '@angular/router';
import { ModalYapeTypes, PATHS, MANDADITO_STEPS } from 'src/app/core/constants';
import { GtmEvent, Modal } from 'src/app/core/interfaces';
import { AuthService } from 'src/app/core/services/auth.service';
import { GtmService } from 'src/app/core/services/gtm.service';
import { ICustomerData } from '../../models/model';

@Component({
  selector: 'app-shared-modals-yape',
  templateUrl: './shared-modals-yape.component.html',
  styleUrls: ['./shared-modals-yape.component.scss']
})
export class SharedModalsYapeComponent implements OnChanges {

  private authService = inject(AuthService);
  private router = inject(Router);
  private gtmService = inject(GtmService);
  @Input() modal: Modal;
  @Input() customer: ICustomerData;
  @Output() closeModalEvent = new EventEmitter<any>()
  modalName = null;
  modals = ModalYapeTypes;
  @Output()
  backStep = new EventEmitter<any>();
  @Input() showModalYape;
  showModal: boolean;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['modal'] && changes['modal'].firstChange === false) {
      this.updateModalData(changes['modal'].currentValue);
    }
  }
  updateModalData(modal: Modal) {
    this.showModal = this.showModalYape;
    this.modalName = modal.name;
    this.gtmService.sendEvent(modal.gtm);
    
  }

  goToReceptorYape(valueReset: boolean): void {
    this.showModal = false;
    this.backStep.emit({step:MANDADITO_STEPS.YAPE, resetReceiver: valueReset});
  }

  goToShippingBCP(): void {
    this.showModal = false;
    this.backStep.emit({step:MANDADITO_STEPS.SHIPPINGMETHOD , resetReceiver: true});
  }

  goToHome() {
    this.showModal = false;
    this.backStep.emit({ step: MANDADITO_STEPS.AMOUNT, resetReceiver: true });
  }
}
