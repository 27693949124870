import { ExceededComponent } from '../views/auth/exceeded/exceeded.component';
export enum OPERATIONS {
  COUPON_STATE = 'COUPON_STATE',
  MANDADITO_SUCCESS = 'MANDADITO_SUCCESS',
  USAR_DATA = 'USAR_DATA',
  OTP_DATA = 'OTP_DATA',
  INFOBIP_STATUS = 'INFOBIP_STATUS',
  FINISH_REGISTER = 'FINISH_REGISTER',
  SESSION_TIME_LIMIT = 'SESSION_TIME_LIMIT',
  ADD_TIME_LIMIT_SESSION = 'ADD_TIME_LIMIT_SESSION',
  SESSION_VALIDATE_TIMER = 'SESSION_VALIDATE_TIMER',
  RESEND_MANDADITO = 'RESEND_MANDADITO',
  RESET_MANDADITO = 'RESET_MANDADITO',
  DATA_OPERATION_MANDADITO = 'DATA_OPERATION_MANDADITO',
  RESEND_MANDADITO_IN_AMOUNT = 'RESEND_MANDADITO_IN_AMOUNT',
  VERIFY_IF_PHONE_EMPTY = 'VERIFY_IF_PHONE_EMPTY',
}

export interface UpdateDataService {
    operation: OPERATIONS;
    data: any;
    success?: boolean;
    clear?: boolean;
    extendedSchedule?: boolean;
    exceededLimitYape?: boolean;
}

export interface ICarouselItem {
    id?: number;
    image: string;
    text: string;
    title: string;
    subtitle?: string;
    link?: string;
    order?: number;
    marginLeft?: number;
  }

  export interface UserInterface {
    fields: User;
    document_front: any;
    document_back: any;
    docTypeloaded?: string;
  }

  export interface LoginInterface {
    type: string;
    email: string;
    password: string;
    sessionId?: string;
  }

  export interface User {
    firstName: string;
    secondName: string;
    firstSurname: string;
    secondSurname: string;
    dni: string;
    email: string;
    terms: boolean;
    data: boolean;
    phonePrefix: string;
    phoneNumber: string;
    country: string;
    state: string;
    address: string;
    origin: string;
    password: string;
    firstQuestion: string;
    firstAnswer: string;
    secondQuestion: string;
    secondAnswer: string;
    ip?: string;
    city?: string;
    browser?: string;
    operationSystem?: string;
    device?: string;
  }

  export interface UpdateRegisterResponse { 
    statusRegister: string;
  }

  export interface UpdateRegisterRequest {
    firstQuestion: string;
    firstAnswer: string;
    secondQuestion: string;
    secondAnswer: string;
  }

  export interface Favorite {
    id?: string;
    firstName: string;
    secondName?: string;
    firstLastname: string;
    secondLastname: string;
    name: string;
    documentType?: string;
    phoneNumber?: string;
    phonePrefix?: string;
    accountNumber: string;
    email: string;
    checked?: boolean;
    myself?: boolean;
    documentNumber?: string;
    accountCurrency?: string;
    typeSendReceptor?: string;
  }

  export interface Promotion {
    id: string;
    code: number;
    discount: number;
    name: string;
    description: string;
    url: string;
    label: string;
    labelTwo: string;
    promotionType?: string;
    rewardId?: string;
  }

  export interface IOperation {
    firstName: string;
    secondName: string;
    firstLastname: string;
    secondLastname: string;
    name: string;
    email: string;
    phonePrefix: string;
    phoneNumber: string;
    receptorNames: string;
    documentNumber: string;
    accountNumber: string;
    commission: number;
    realCommission?: number;
    discount?: Discount;
    amount: number;
    payCode: string;
    payAmount: number;
    message: string;
    ip: string;
    city: string;
    region: string;
    country: string;
    isFavorite: boolean;
    checkFavorite: boolean;
    autoRemittance: boolean;
    paymentSource?: string;
    ipProvider?: string;
    extendedSchedule?: boolean;
    origin?: string | null;
    promotion: {
      id: string;
      type: string;
    }
    currency:string;
    typeSendReceptor: string;
  }

  export interface Discount {
    show?: boolean;
    value?: number;
    label?: string;
    labelTwo?: string;
    url?: string;
    id?: string;
    type?: string;
    promotionType?: string;
    rewardId?: string;
  }

  export interface DataMandadito {
    receiver: Receiver;
    operation: OperationMandadito;
    indexSelected: number;
    isFavorite: boolean;
    checkFavorite: boolean;
    autoremittance: boolean;
    extendedSchedule: boolean;
    coupon?: CouponData;
    testTransaction?: boolean;
    promotion?: {
      id: string;
      type: string;
      rewardId?: string;
    }
    currency?: string;
    typeSendReceptor?: string;
  }

  export interface Receiver {
    id?: string;
    name: string;
    receptorNames?: string;
    accountNumber: string;
    message?: string;
    firstName: string;
    secondName: string;
    firstLastname: string;
    secondLastname: string;
    documentNumber: string;
    email: string;
    phonePrefix: string;
    phoneNumber: string;
    customerState?: string;
    hasOperationsCompleted?: boolean;
    totalAmount?: number;
    checked?: boolean,
    myself?: boolean,
    accountCurrency?: string;
    exchangeRate ?: number;
  }

  export interface OperationMandadito {
    currency: string;
    amount: number;
    commission: number;
    realCommission: number;
    discount: Discount;
    promotion: Promotion;
    total: number;
    autoremittance?: boolean;
    isFavorite?: boolean;
    origin: string | null;
  }

  export interface GtmEvent {
    event: string;
    accion: string;
    pantalla: string;
    email?: string;
  };

  export interface Modal {
    name: string;
    gtm?: GtmEvent;
    userEmail?: string;
    hasOperationsCompleted?: boolean;
    hasOperationsValid?: boolean;
    isRepeatingMandadito?: boolean;
    maxAmountMandadito?:number;
  }

  export interface Coupon {
    show: boolean;
    value: number;
    label: string;
    labelTwo: string;
    url: string;
    id: string;
    link: string;
    discount: number;
  };

  export interface Operation {
    id: string;
    createdAt: string;
    amount: number,
    currency: string;
    receiverFirstName: string;
    receiverSecondName: string;
    receiverLastname: string;
    receiverSecondLastname: string;
    autoRemittance?: boolean;
    status:string;
    favorites?: string;
    isFavorite?: boolean;
    requestedAmount?: number;
    receiverAccount:string;
  }

  export interface OperationDetail {
    id: string;
    createdAt: string;
    amount: number,
    currency: string;
    receiverId: string;
    receiverFirstName: string;
    receiverSecondName: string;
    receiverLastname: string;
    receiverSecondLastname: string;
    comission: number,
    receiverAccount: string;
    codeOperation: string;
    sendToReceiverAt: string;
    exchangeRate?: number;
  }

  export interface CouponData {
    amount: number;
    code: string;
    couponType: 'Comisión' | 'Monto' | 'Tipo de cambio';
    currency: 'PEN' | 'USD';
    description: string;
    discount: number;
    endDate: string;
    id: string;
    label: string;
    link: string;
    sendingNumber: number;
    sendingOnwards: boolean;
    startDate: string;
    status: boolean;
    profitExchangeRate?: number;
    show?: boolean;
    value?: number;
    labelTwo?: string;
    url?: string;
  }

export interface UserValidationData {
  maxAmountDay: number;
  maxAmountMandadito:number;
  maxQuantityMonth:number;
  extendedSchedule: boolean;
  testTransaction: boolean;
  commission: CommissionTable;
}

export interface IRewards{
  data: any[];
  total: number;
}

export interface MandaditoQueryParams {
  receiverId: string;
  amount: number;
  origin: string | null;
}

export interface IPublicExchangeRate {
    exchangeRate: number;
    exchangeRateFinal: number;
    hasOperationValid: boolean
}

export interface IRegisterUserResponse {
  existsUser: boolean;
  hasImcompleteRegister: boolean;
};

export interface ILocationApi {
  isLocationAllowed: boolean;
  regionCode: string;
  countryCode: string;
  ip: string;
  city: string;
  region: string;
  countryCodeIso: string;
  provider: string;
}

export interface CommissionTable {
  [key: number]: number
}

// Define the interfaces for each nested object OBJECT YAPE

export interface Identifier {
  value: string;
  type: string;
  countryCode: string;
}

export interface Account {
  accountNumber: string;
}

export interface Beneficiary {
  fullName: string;
  identifier: Identifier;
  account: Account;
}

export interface Sender {
  type: string;
  identifier: Identifier;
  fullName: string;
  addressLine1: string;
  city: string;
  state: string;
  zipCode: string;
  nationality: string;
  dateOfBirth: string;
  gender: string;
}

export interface Amount {
  paymentAmount: number;
  paymentCurrency: string;
}

export interface AdditionalInfo {
  remittance: string;
  partner: string;
  channelType: string;
  originCountryCode: string;
}

// Define the main interface that uses the above nested interfaces

export interface PaymentYape {
  paymentInfo?: {
    beneficiary?: Beneficiary;
  };
  sender?: Sender;
  amount?: Amount;
  additionalInfo?: AdditionalInfo;
}