import { Component, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { IBreadcrumbItem } from '../../models/model';
import { Router } from '@angular/router';
import { BreadcrumbService } from 'src/app/core/services/breadcrumb.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit {
  
  @Input() data: IBreadcrumbItem[] = [];
  isDesktop = true;
  breadcrumbSubscription$: Subscription

  constructor(private router: Router) { }

  ngOnInit(): void {
    this.checkPlatform()
  }

  goToRoute(route: string) {
    this.router.navigate([route])
  }

  /**
   * We listen to any windows resize event so we can track the viewport size
   * @param event 
   */
  @HostListener('window:resize', ['$event'])
  onResizeEvent(event: any) {
    this.checkPlatform()
  }

  private checkPlatform() {
    // Using 768px as threshold for mobile devices
    this.isDesktop = window.innerWidth >= 786;
  }
}
