<div *ngFor="let favorite of elements; let i = index" class="favorite cursor-pointer" (click)="selectFavorite(favorite)" >
    <div class="favorite-info">
        <div class="favorite-letter"
            [ngClass]="sendMethod">{{ getCurrency(favorite?.accountCurrency)}}</div>
        <div class="favorite-data">
            <span class="name">{{ favorite.myself || (!favorite.myself && favorite.documentNumber === customer.documentNumber)? '(Yo) ' : '' }}{{ favorite.name }}</span>
            <span class="account" *ngIf="favorite.typeSendReceptor === 'YAPE'">Yape +51 {{ favorite.accountNumber | maskPhone }}</span>
            <span class="account" *ngIf="favorite.typeSendReceptor !== 'YAPE'">BCP {{ favorite.accountNumber | maskAccountNumber }}</span>
        </div>
    </div>
    <bcp-icon name="angle-right-r" ></bcp-icon>
</div>
