import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'maskPhone'
})
export class PhoneMaskPipe implements PipeTransform {

  transform(value: string) {

    return this.formatPhoneNumber(value);
  }

  private formatPhoneNumber(value: string): string {
    const cleaned = ('' + value).replace(/\D/g, '');
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{3})$/);

    return match ? `*** *** ${match[3]}` : '';
  }
}
