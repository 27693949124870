import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'maskAccountNumber'
})
export class MaskAccountNumberPipe implements PipeTransform {

  transform(value: string): string {
    return this.maskAccountNumber(value);
  }

  private maskAccountNumber(accountNumber:string): string {
    let maskAccount = '***************';
    if (accountNumber) {
      maskAccount = `*** ******* ${accountNumber.substring(11,12)} ${accountNumber.substring(12,14)}`;
    }

    return maskAccount;
  }
}
