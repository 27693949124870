<header translate="no" [ngClass]="{'border': border, 'sticky':sticky}">
    <div class="contain">
        <img class="full-screen header-logo" [src]="isLanding ? logoDesktop : logoLightDesk" (click)="goToLanding()" alt="BCP logo" width="207" height="24">
        <img class="mobile header-logo" [src]="isLanding ? logoMobile : logoLightMob" (click)="goToLanding()" alt="BCP logo" width="85" height="32">
        <ul>
            <li *ngIf="isLanding" class="full-screen">
                <p>
                    <a class="flexo-demi" href="javascript:;" href="https://wa.link/ny1jx2" target="_blank" rel="noopener">
                        <img src="assets/images/landing/whatsapp-icon.svg" class="whatsapp-icon" alt="Whatsapp" width="24" height="24" />Contáctanos
                    </a>
                </p>
            </li>
            <li *ngIf="isLanding" class="full-screen">
                <p>
                    <a class="flexo-demi" href="javascript:;" (click)="goToFAQs()">
                        <img src="/assets/images/q_ic_question_circle.svg" class="question-icon" alt="Frequently Asked Questions" width="24" height="24" />Preguntas frecuentes
                    </a>
                </p>
            </li>
            <li class="header__btn">
                <app-button shape="rectangle" tier="primary" size="md" full-width="true" (ctrlClick)="goToRegister()">Registrarme</app-button>
            </li>
            <li class="header__btn">
                <app-button shape="rectangle" tier="secondary" size="md" full-width="true" [mode]="isLanding ? 'dark': 'light'" (ctrlClick)="goToLogin()">Login</app-button>
            </li>
        </ul>
    </div>
</header>
