import { AfterViewInit, Component, ElementRef, HostListener, Input, OnDestroy, OnInit, inject } from '@angular/core';
import { Router } from '@angular/router';
import { ASSETS, PATHS } from '../../constants';
import { GtmService } from '../../services/gtm.service';
import { GtmEvent } from '../../interfaces';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, AfterViewInit, OnDestroy{

  @Input()
  border = true;

  @Input()
  sticky = false;

  isHeaderPinned= false;
  isDesktop = false;

  @Input()
  isLanding = false;

  private gtmService = inject(GtmService);
  private router = inject(Router);
  private elementRef = inject(ElementRef)

  private observer: IntersectionObserver
  private observerSubject$ = new BehaviorSubject(false)

  logoDesktop: string = ASSETS.IMG_LOGO_DESKTOP;
  logoMobile: string = ASSETS.IMG_LOGO_MOBILE;
  logoLightDesk: string = ASSETS.IMG_LOGO_CHIP;
  logoLightMob: string = ASSETS.IMG_LOGO_MOB_CHIP;

  ngOnInit(): void {
    this.initializeComponent()
  }
  ngAfterViewInit(): void {
    this.observe()
  }

  initializeComponent() {
    this.onResize()
    this.createObserver()
  }

  goToLanding(): void {
    this.router.navigate([PATHS.LANDING]);
  }

  goToFAQs(): void {
    this.router.navigate([PATHS.FAQs]);
  }

  goToLogin(): void {
    const event: GtmEvent = {
      event: 'virtualEvent',
      accion: 'Click_Landing_LogIn',
      pantalla: 'Landing',
    };
    this.gtmService.sendEvent(event);
    this.router.navigate([PATHS.LOGIN]);
  }

  goToRegister(): void {
    const event: GtmEvent = {
      event: 'virtualEvent',
      accion: 'Registrate_fijo',
      pantalla: 'Landing',
    };
    this.gtmService.sendEvent(event);
    this.router.navigate([PATHS.REGISTER]);
  }

  private createObserver() {
    const options = {
      root: null,
      rootMargin: '0px 0px 0px 0px',
      threshold: 1
    }

    const isPinnedToTop = (entry: IntersectionObserverEntry): boolean => {
      return entry.intersectionRatio < 1
    }

    this.observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        this.observerSubject$.next(isPinnedToTop(entry))
      })
    }, options)
  }

  private observe() {
    if (!this.observer) return
    this.observer.observe(this.elementRef.nativeElement)

    this.observerSubject$.subscribe(pinned => {
      this.isHeaderPinned = pinned
    })
  }

  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    this.isDesktop = window.innerWidth > 768
  }

  ngOnDestroy(): void {
    this.observer.disconnect()
    this.observerSubject$.complete()
    this.observerSubject$.unsubscribe()
  }
}
