import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Favorite } from 'src/app/core/interfaces';
import { Currency, ICustomerData } from '../../models/model';

@Component({
  selector: 'app-favorite-card',
  templateUrl: './favorite-card.component.html',
  styleUrls: ['./favorite-card.component.scss']
})
export class FavoriteCardComponent {

  @Input()
  elements: Favorite[] = null;

  @Input()
  customer: ICustomerData

  @Input()
  currency: string

  @Input()
  sendMethod: string;

  @Output()
  elementEmitter = new EventEmitter();

  CurrencyTypes = Currency;

  filteredFavorites = new Array<Favorite>();

  selectFavorite(favorite: Favorite) {
    this.elementEmitter.emit(favorite)
  }

  getCurrency(currency: string) {
    const value = currency || 'PEN';

    return this.CurrencyTypes[value].symbol;
  }
}
