import { Component, ElementRef, HostListener, OnInit, ViewChild, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PATHS } from 'src/app/core/constants';
import { GtmEvent } from 'src/app/core/interfaces';
import { GtmService } from 'src/app/core/services/gtm.service';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';
import { ICarouselItem } from 'src/app/shared/models/model';
import { environment } from 'src/environments/environment';
import { AnswerQuestions, ItemsCarousel, TariffCards } from './landing.data';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss']
})
export class LandingComponent implements OnInit {

  isDesktop: boolean;
  imgLoaded = false;
  @ViewChild('bcpElement', { static: false }) bcpElement: ElementRef;

  private router = inject(Router)
  private activatedRoute = inject(ActivatedRoute)
  private localStorageService = inject(LocalStorageService)

  PATHS = PATHS
  itemsCarousel = ItemsCarousel;
  tariffCards = TariffCards;
  answerQuestions = AnswerQuestions;
  showModalTariff = false
  showBanner: boolean = false;

  private gtmService = inject(GtmService);

  ngOnInit(): void {
    this.onResize()
    this.saveReferralCode()
  }

  goToRegister(action: string) {
    const event: GtmEvent = {
      event: 'virtualEvent',
      accion: action,
      pantalla: 'Landing',
    };
    this.gtmService.sendEvent(event);
    this.router.navigate([PATHS.REGISTER])
  }

  setBanner(event: boolean) {
    this.showBanner = event;
  }

  goToFAQs(): void {
    const event: GtmEvent = {
      event: 'virtualEvent',
      accion: 'Click_Landing_PreguntasF',
      pantalla: 'Landing',
    };
    this.gtmService.sendEvent(event);
    this.router.navigate([PATHS.FAQs])
  }

  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    this.isDesktop = window.innerWidth >= 768
  }

  setBackgroundGradient(){
    // This is fired once the image is loaded and the class with gradient BG is added to the header element
    this.imgLoaded=true
  }

  goToTerms() {
    this.router.navigate([PATHS.TERMS])
  }

  saveReferralCode() {
    this.activatedRoute.queryParams.subscribe(queryParams => {
      if ('referralcode' in queryParams) {
        this.localStorageService.setStorage('referralCode', queryParams['referralcode'])
      }
    })
  }

  tagGotoSolutions(accion): void {
    const event: GtmEvent = {
      event: 'virtualEvent',
      accion,
      pantalla: 'Landing',
    };
    this.gtmService.sendEvent(event);  
  }
}
