import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map, retry } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CouponData, Favorite, IOperation, IPublicExchangeRate, IRewards, Promotion, Receiver, User, UserValidationData } from '../interfaces';
import { ICustomerData } from 'src/app/shared/models/model';

const headers = {
  headers: { 'Ocp-Apim-Subscription-Key': environment.ocpApimSubscriptionKey }
};

@Injectable({
  providedIn: 'root'
})
export class MainService {

  constructor(private http: HttpClient) { }
  public getFavorites(): Observable<Favorite[]> {
    return this.http.get<Favorite[]>(`${environment.urlBase}people/GetReceivers`, headers);
  }

  public getAvailablePromotions(): Observable<Promotion[]> {
    return this.http.get<{ promotions: Promotion[] }>(`${environment.urlBase}discounts/GetPromotions`, headers)
      .pipe(
        map((response: { promotions: Promotion[] }) => response.promotions)
      );
  }

  public getValidationUserOperations(amount?: number):Observable<UserValidationData> {
    let withAmount = amount ? `?amount=${amount}` : '';
    return this.http.get<UserValidationData>(`${environment.urlBase}operations/ValidateUserOperations${withAmount}`, headers)
      .pipe(
        retry(1)
      );
  }

  public getCouponData(code: string, currency: string): Observable<CouponData> {
    return this.http.get<CouponData>(`${environment.urlBase}discounts/GetCoupon?code=${code}&currency=${currency}`, headers).pipe(
      map( (data: any) => {
        return data.coupon;
      })
    );
  }

  saveOperation(data: IOperation): Observable<any> {
    return this.http.post(`${environment.urlBase}operations/CreateOperation`, data, headers)
      .pipe(
        retry(1)
      );
  }

  existsUserByEmail(email: string): Observable<any> {
    return this.http.get(`${environment.urlBase}users/ValidateUser?email=${email}`, headers);
  }

  GetCustomer(): Observable<ICustomerData|any> {
    return this.http.get<ICustomerData>(`${environment.urlBase}people/GetCustomer`, headers);
  }

  getOperations(size: number, state?: string, period?: string): Observable<any> {
    return this.http.get(`${environment.urlBase}operations/ListOperations?pageSize=${size}${state ? '&state=' + state : ''}${period ? '&period=' + period : ''}`, headers);
  }

  getOperationDetail(id: string): Observable<any> {
    return this.http.get(`${environment.urlBase}operations/GetOperation/${id}`, headers);
  }

  isWithinAccessTime(email?: string): Observable<{ isWithinAccessTime: boolean }> {
    return this.http
      .get<{ isWithinAccessTime: boolean }>(`${environment.urlBase}users/IsWithinAccessTime`.concat(email ? `?email=${email}` : ''), headers);
  }

  saveUserLog(userLog: User) {
    return this.http.post(`${environment.urlBase}users/Users`, userLog, headers);
  }

  sendLogOperation(operation) {
    return this.http.post(`${environment.urlBase}operations/LogOperation`, operation, headers);
  }

  validateIfDocumentNumberAlreadyRegistered(documentNumber: string): Observable<any> {
    return this.http.get(`${environment.urlBase}people/DocumentAlreadyRegistered?&documentNumber=${ documentNumber }`, headers);
  }

  registerRestricterUserLocation(data) {
    return this.http.post(`${environment.urlBase}people/RegisterEmail`, data, headers);
  }

  getReceiver(receiverId: string){
    return this.http.get<Receiver>(`${environment.urlBase}people/GetReceiver/${receiverId}`, headers)
  }

  getRewards(): Observable<IRewards> {
    return this.http.get<IRewards>(`${environment.urlBase}discounts/GetRewards`, headers)
  }

  getPublicExchangeRateDni(dni: number, uuid: string, captcha?: string): Observable<IPublicExchangeRate> {
    let payload = {
      dni, 
      sessionId: uuid,
    } as any;

    if (captcha) {
      payload.captcha = captcha.trim();
    }

    return this.http.post<IPublicExchangeRate>(`${environment.urlBase}public/getExchangeRate`, payload, headers)
  }

  ValidateRemittYape(operation) {
    return this.http.post(`${environment.urlBase}operations/ValidateRemittYape`, operation, headers);
  }
}
